import * as React from "react"
import { Hit as AlgoliaHit } from "@algolia/client-search"
import { useHits, UseHitsProps } from "react-instantsearch-hooks"
import { List } from "antd"

export type HitsProps<THit> = React.ComponentProps<"div"> &
  UseHitsProps & {
    hitComponent: (props: { hit: THit }) => JSX.Element
  }

export function Hits<THit extends AlgoliaHit<Record<string, unknown>>>({
  hitComponent: Hit,
}: HitsProps<THit>) {
  const { hits } = useHits()

  return (
    <List
      itemLayout="vertical"
      dataSource={hits}
      renderItem={(hit) => (
        <List.Item>
          <Hit hit={hit as unknown as THit} />
          {/* <List.Item.Meta
            // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
            title={<a href="https://ant.design">{hit.title}</a>}
            description={<Hit hit={hit as unknown as THit} />}
          /> */}
        </List.Item>
      )}
    />
  )

  return (
    <div className="ais-Hits">
      <ol className="ais-Hits-list">
        {hits.map((hit) => (
          <li key={hit.objectID} className="ais-Hits-item">
            <Hit hit={hit as unknown as THit} />
          </li>
        ))}
      </ol>
    </div>
  )
}
