import * as React from "react"
import { useSearchBox, UseSearchBoxProps } from "react-instantsearch-hooks"
import { Form, Input, Button, Checkbox } from "antd"

export type SearchBoxProps = UseSearchBoxProps

const { Search } = Input

export const SearchBox: React.FC<{ props: SearchBoxProps }> = (props) => {
  const { query, refine, isSearchStalled } = useSearchBox(props)
  const [inputValue, setInputValue] = React.useState(query)

  //   const inputRef = React.useRef<HTMLInputElement>(null)

  //   function handleSubmit(event: React.FormEvent) {
  //     event.preventDefault()
  //     event.stopPropagation()

  //     if (inputRef.current) {
  //       inputRef.current.blur()
  //     }
  //   }

  //   Track when the value coming from the React state changes to synchronize
  //   it with InstantSearch.
  React.useEffect(() => {
    if (query !== inputValue) {
      refine(inputValue)
    }
  }, [inputValue, query, refine])

  //   Track when the InstantSearch query changes to synchronize it with
  //   the React state.
  //   React.useEffect(() => {
  //     // Bypass the state update if the input is focused to avoid concurrent
  //     // updates when typing.
  //     if (document.activeElement !== inputRef.current && query !== inputValue) {
  //       setInputValue(query)
  //     }
  //   }, [query])

  return (
    <div className="ais-SearchBox">
      <Search
        size="large"
        placeholder="What are you looking for?"
        onSearch={(value) => {
          console.log("Setting input value", value)
          return setInputValue(value)
        }}
        //   autoComplete="off"
        //   autoCorrect="off"
        //   autoCapitalize="off"
        //   placeholder={props.placeholder}
        //   spellCheck={false}
        //   maxLength={512}
        //   type="search"
        //   value={inputValue}
        //   onChange={(event) => setInputValue(event.currentTarget.value)}
      />
      {/* </form> */}
    </div>
  )
}
