import * as React from "react"
import { Hit as AlgoliaHit } from "@algolia/client-search"
import { JsonDebugger } from "../json-debugger"
import { Highlight } from "./highlight"
type THit = AlgoliaHit<Record<string, unknown>>
import { Avatar, Badge, Card, List, Typography } from "antd"
import { TextProps } from "antd/lib/typography/Text"
import { Link } from "gatsby"
import { Snippet } from "./snippet"
import CartIcon from "../../icons/cart"
import { QuestionIcon } from "../../icons/question"
import { PageIcon } from "../../icons/page"
import { GatsbyImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"
import { FAQIcon } from "../../icons/faq"

const { Text, Title, Paragraph } = Typography
// interface THit extends AlgoliaHit<Record<string, unknown>> {}
const hitImage: React.ReactNode = (hit: THit) => {
  const width = 64
  const { image, type } = hit
  if (image && image.src) {
    let imageSrc = image.src
    if (type.toLowerCase() === "product") {
      const storefrontImageData = getShopifyImage({
        image: { ...image, originalSrc: image.src, altText: image.alt },
        // layout: "constrained",
        layout: "fullWidth",
        width: width,
        height: width,
        // style: { display: "inline-block" }
      })
      const coverImage = (
        <GatsbyImage
          alt={image.alt || ""}
          image={storefrontImageData}
          // loading={eager ? "eager" : "lazy"}
        />
      )
      return (
        <Avatar
          shape="square"
          size={width}
          icon={coverImage}
          style={{ background: "#fff" }}
        />
      )
    }
    return <img src={image.src} alt={image.alt || hit.title} />
  } else {
    switch (type.toLowerCase()) {
      case "product":
        // return <CartIcon />
        return <Avatar shape="square" size={width} icon={<CartIcon />} />
      case "faq":
        // return <QuestionIcon />
        return <Avatar shape="square" size={width} icon={<FAQIcon />} />
      case "page":
        // return <PageIcon />
        return <Avatar shape="square" size={width} icon={<PageIcon />} />
      default:
        return null
    }
  }
}

export const Hit: React.FC<{ hit: THit }> = ({ hit }) => {
  return (
    <Link
      to={hit.url}
      style={{
        minWidth: "100%",
      }}
    >
      <Badge.Ribbon text={hit.type}>
        <Card
          /*title={<Highlight attribute="title" hit={hit} />} */
          hoverable
        >
          <Card.Meta
            avatar={hitImage(hit)}
            title={<Highlight attribute="title" hit={hit} />}
            description={
              <Paragraph>
                <Snippet attribute="body" hit={hit} />
                {/* <JsonDebugger json={hit} /> */}
                <Text disabled>
                  {hit.url.split("/").slice(1, -1).join(" › ")}
                </Text>
              </Paragraph>
            }
          />
          {/* <Paragraph>
        <Snippet attribute="body" hit={hit} />
      </Paragraph> */}
        </Card>
      </Badge.Ribbon>
    </Link>
  )
}
