import * as React from "react"
import { Hit as AlgoliaHit } from "@algolia/client-search"
import {
  getHighlightedParts,
  getPropertyByPath,
} from "instantsearch.js/es/lib/utils"

import { Typography } from "antd"
import { TextProps } from "antd/lib/typography/Text"

const { Text } = Typography
type HighlightPartProps = {
  children: React.ReactNode
  isHighlighted: boolean
}

function HighlightPart({ children, isHighlighted }: HighlightPartProps) {
  //   const type = isHighlighted ? highlightedTextType : nonHighlightedTextType

  return <Text mark={isHighlighted}>{children}</Text>
}

export type HighlightProps<THit> = {
  hit: THit
  attribute: keyof THit | string[]
}

export function Highlight<THit extends AlgoliaHit<Record<string, unknown>>>({
  hit,
  attribute,
  ...rest
}: HighlightProps<THit>) {
  const { value: attributeValue = "" } =
    getPropertyByPath(hit._highlightResult, attribute as string) || {}
  const parts = getHighlightedParts(attributeValue)
  console.log({ parts })
  return (
    <span {...rest}>
      {parts.map((part, partIndex) => {
        return (
          <Text key={partIndex} mark={part.isHighlighted}>
            {/* {part.value} */}
            <span dangerouslySetInnerHTML={{ __html: part.value }} />
          </Text>
          //   <HighlightPart key={partIndex} isHighlighted={part.isHighlighted}>
          //     {part.value}
          //   </HighlightPart>
        )
      })}
    </span>
  )
}
