import * as React from "react"
// import { graphql } from "gatsby"
import { SearchProvider } from "../context/search-provider"
import Layout from "../components/layout"
// import { ProductCard } from "../components/product-card"
// import { getValuesFromQueryString, useProductSearch } from "../utils/hooks"
import { Helmet } from "react-helmet"
import { orderBy } from "lodash"
import { ProductCard } from "../components/product-card"
import { searchIcon } from "./search-page.module.css"

import algoliasearch from "algoliasearch/lite"
import { InstantSearch, Configure } from "react-instantsearch-hooks"
// import {
//   InstantSearch,
//   // SearchBox,
//   Hits,
//   Highlight,
//   Menu,
//   RefinementList,
// } from "react-instantsearch-dom"
import PageContent from "../components/page-content"
import { SearchBox } from "../components/search/search-box"
import { Hits } from "../components/search/hits"
import { Hit } from "../components/search/hit"
import { RefinementList } from "../components/search/refinement-list"
import { Pagination } from "../components/search/pagination"
import { Col, Row, Space } from "antd"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APPLICATION_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
)

// const Hit: React.FC<{ hit: unknown }> = ({ hit }) => {
//   // const price = hit?.price ? formatPrice("USD", hit.price) : "N/A"
//   const url = hit?.sku ? `/r/${hit.sku}` : `/product/${hit.handle}`
//   const product = {
//     data: {
//       shopify_product: {
//         title: hit.title,
//         image: {
//           src: hit.image,
//         },
//         variants: [{ price: hit.price }],
//       },
//     },
//     url,
//   }
//   // return JSON.stringify(hit)
//   return <ProductCard product={product} poppedProduct={false} eager={false} />
//   return (
//     <p>
//       <Highlight attribute="title" hit={hit} tagName="mark" />
//     </p>
//   )
// }

function SearchPage() {
  return (
    <Layout>
      <PageContent>
        <InstantSearch searchClient={searchClient} indexName="general_search">
          {/* <InstantSearch searchClient={searchClient} indexName="site_pages"> */}
          {/* <Menu
            attribute="categories"
            showMore={true}
            // translations={{
            //   showMore: 'Voir plus'
            // }}
          /> */}
          {/* <RefinementList
            attribute="categories"
            transformItems={(items) => orderBy(items, "label", "asc")}
          /> */}
          <Configure hitsPerPage={10} />
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <SearchBox />
            </Col>
            <Col span={24} md={5}>
              <Space direction="vertical" size="large">
                <RefinementList attribute="type" title="Result Type" />
                <RefinementList attribute="product_type" title="Product Type" />
                {/* <RefinementList attribute="vendor" title="Vendor" /> */}
                <RefinementList attribute="category" title="FAQ Category" />
              </Space>
            </Col>
            <Col span={24} md={19}>
              <Hits hitComponent={Hit} />
              <Pagination />
            </Col>
          </Row>
        </InstantSearch>
        <p></p>
      </PageContent>
      {/* <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/instantsearch.css@7.3.1/themes/reset-min.css"
          integrity="sha256-t2ATOGCtAIZNnzER679jwcFcKYfLlw01gli6F6oszk8="
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/instantsearch.css@7.4.5/themes/satellite-min.css"
          integrity="sha256-TehzF/2QvNKhGQrrNpoOb2Ck4iGZ1J/DI4pkd2oUsBc="
          crossOrigin="anonymous"
        />
      </Helmet> */}
    </Layout>
  )
}

export default function SearchPageTemplate(props) {
  return (
    <SearchProvider>
      <SearchPage {...props} />
    </SearchProvider>
  )
}
