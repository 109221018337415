import * as React from "react"
import { usePagination, UsePaginationProps } from "react-instantsearch-hooks"
import { cx } from "./cx"
import { isModifierClick } from "./isModifierClick"
import { Pagination as AntPagination } from "antd"

export type PaginationProps = React.ComponentProps<"div"> & UsePaginationProps

export function Pagination(props: PaginationProps) {
  const {
    refine,
    createURL,
    pages,
    currentRefinement,
    isFirstPage,
    isLastPage,
    nbPages,
    canRefine,
  } = usePagination(props)
  console.log({
    pages,
    currentRefinement,
    isFirstPage,
    isLastPage,
    nbPages,
    canRefine,
  })
  const test = (
    <AntPagination
      pageSize={1}
      current={1 + (currentRefinement || 0)}
      // defaultCurrent={0}
      total={nbPages}
      onChange={(page) => {
        refine(page - 1)
      }}
    />
  )
  return test
  return (
    <div
      className={cx(
        "ais-Pagination",
        canRefine === false && "ais-Pagination--noRefinement",
        props.className
      )}
    >
      {test}
      <ul className="ais-Pagination-list">
        <PaginationItem
          aria-label="First"
          value={0}
          isDisabled={isFirstPage}
          createURL={createURL}
          refine={refine}
          className={cx(
            "ais-Pagination-item",
            "ais-Pagination-item--firstPage"
          )}
        >
          ‹‹
        </PaginationItem>

        <PaginationItem
          aria-label="Previous"
          value={currentRefinement - 1}
          isDisabled={isFirstPage}
          createURL={createURL}
          refine={refine}
          className={cx(
            "ais-Pagination-item",
            "ais-Pagination-item--previousPage"
          )}
        >
          ‹
        </PaginationItem>

        {pages.map((page) => (
          <PaginationItem
            key={page}
            aria-label={String(page)}
            value={page}
            isDisabled={false}
            createURL={createURL}
            refine={refine}
            className={cx(
              "ais-Pagination-item",
              page === currentRefinement && "ais-Pagination-item--selected"
            )}
          >
            {page + 1}
          </PaginationItem>
        ))}

        <PaginationItem
          aria-label="Next"
          value={currentRefinement + 1}
          isDisabled={isLastPage}
          createURL={createURL}
          refine={refine}
          className={cx("ais-Pagination-item", "ais-Pagination-item--nextPage")}
        >
          ›
        </PaginationItem>

        <PaginationItem
          aria-label="Last"
          value={nbPages - 1}
          isDisabled={isLastPage}
          createURL={createURL}
          refine={refine}
          className={cx("ais-Pagination-item", "ais-Pagination-item--lastPage")}
        >
          ››
        </PaginationItem>
      </ul>
    </div>
  )
}

type PaginationItemProps = React.ComponentProps<"a"> &
  Pick<ReturnType<typeof usePagination>, "refine" | "createURL"> & {
    isDisabled: boolean
    value: number
  }

function PaginationItem(props: PaginationItemProps) {
  const { isDisabled, className, href, value, createURL, refine, ...rest } =
    props

  if (isDisabled) {
    return (
      <li className={cx(className, "ais-Pagination-item--disabled")}>
        <span className="ais-Pagination-link" {...rest} />
      </li>
    )
  }

  return (
    <li className={className}>
      <a
        className="ais-Pagination-link"
        href={createURL(value)}
        onClick={(event) => {
          // We let the browser handle the native event when the click is modified
          // (with a special mouse event or with a combination key) for accessibility
          // purposes. For example, clicking the link while holding the Control
          // key opens the next search state link in a new tab, but doesn't refine
          // the search state in the current tab.
          if (isModifierClick(event)) {
            return
          }

          event.preventDefault()
          refine(value)
        }}
        {...rest}
      />
    </li>
  )
}
