import * as React from "react"
import { Badge, Checkbox, Space, Typography } from "antd"
import {
  useRefinementList,
  UseRefinementListProps,
} from "react-instantsearch-hooks"
import { cx } from "./cx"
const { Text } = Typography

export type RefinementListProps = React.ComponentProps<"div"> &
  UseRefinementListProps

export function RefinementList(props: RefinementListProps) {
  const { items, refine } = useRefinementList(props)
  const { attribute, title } = props
  console.log({ items })
  const value = items.filter((item) => item.isRefined).map((item) => item.value)
  if (items.length <= 0) {
    return null
  }
  return (
    <>
      <Space direction="vertical" size={2}>
        <Text strong>{title || attribute}</Text>
        {items.map((item, key) => (
          <Checkbox
            key={item.label}
            checked={item.isRefined}
            onChange={() => refine(item.value)}
          >
            {item.label} <Badge count={item.count} />
          </Checkbox>
        ))}
      </Space>
      {/* <Checkbox.Group
        name={attribute}
        value={value}
        options={items.map((item) => ({
          ...item,
          label: (
            <>
              {item.label}{" "}
              <Badge count={item.count} style={{ backgroundColor: "#ccc" }} />
            </>
          ),
        }))}
        onChange={(checkedValues) => {
          console.log({ checkedValues })
          if (checkedValues.length) {
            checkedValues.every((value) => refine(value))
          } else {
            // refine()
          }
        }}
        // onChange={onChange}
      />
      */}
    </>
  )

  return (
    <div className="ais-RefinementList">
      <Title level={4}>{title || attribute}</Title>
      <ul className="ais-RefinementList-list">
        {items.map((item) => (
          <li
            key={item.value}
            className={cx(
              "ais-RefinementList-item",
              item.isRefined && "ais-RefinementList-item--selected"
            )}
          >
            <label className="ais-RefinementList-label">
              <input
                className="ais-RefinementList-checkbox"
                type="checkbox"
                value={item.value}
                checked={item.isRefined}
                onChange={() => refine(item.value)}
              />
              <span className="ais-RefinementList-labelText">{item.label}</span>
              <span className="ais-RefinementList-count">{item.count}</span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  )
}
