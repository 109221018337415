import * as React from "react"
import { Hit as AlgoliaHit } from "@algolia/client-search"
import {
  getPropertyByPath,
  getHighlightedParts,
} from "instantsearch.js/es/lib/utils"

import { Typography } from "antd"
import Paragraph from "antd/lib/typography/Paragraph"

const { Text } = Typography
type HighlightPartProps = {
  children: React.ReactNode
  isHighlighted: boolean
}

export type SnippetProps<THit> = {
  hit: THit
  attribute: keyof THit | string[]
}

export function Snippet<THit extends AlgoliaHit<Record<string, unknown>>>({
  hit,
  attribute,
  ...rest
}: SnippetProps<THit>) {
  const { value: snippetValue = "" } =
    getPropertyByPath(hit._snippetResult, attribute as string) || {}
  const parts = getHighlightedParts(snippetValue)
  console.log({ snippetValue, parts })
  // return <Text>{snippetValue}</Text>
  return (
    <Paragraph {...rest}>
      {parts.map((part, partIndex) => {
        return (
          <Text key={partIndex} mark={part.isHighlighted}>
            <span dangerouslySetInnerHTML={{ __html: part.value }} />
            {/* <p dangerouslySetInnerHTML={{__html:"&amp;nbsp;"}}/> */}
            {/* {part.value} */}
          </Text>
          //   <HighlightPart key={partIndex} isHighlighted={part.isHighlighted}>
          //     {part.value}
          //   </HighlightPart>
        )
      })}
    </Paragraph>
  )
}
